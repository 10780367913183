import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController ) { }

  async success(text: string, duration=2000) {
    this.makeToast(text, 'success', duration);
  }

  async info(text: string, duration=2000) {
    this.makeToast(text, 'dark',duration);
  }

  async warning(text: string, duration=2000) {
    this.makeToast(text, 'warning', duration);
  }

  async error(text: string, duration=2000) {
    this.makeToast(text, 'danger', duration);
  }

  async proGreen(text: string, duration=2000) {
    this.makeToastProGreen(text, duration);
  }

  hide(){
    this.toastController.dismiss();
  }
  private async makeToastProGreen(text: string, duration: number){
    const toast = await this.toastController.create({
      message: text,
      position: 'top',
      duration,
      cssClass: 'pro-toast',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {}
        }
      ]
    });
    await toast.present();
  }

  private async makeToast(text: string, type = 'success', duration: number){
    const toast = await this.toastController.create({
      message: text,
      color: type,
      position: 'top',
      duration,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {}
        }
      ]
    });
    await toast.present();
  }
}
