import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'detail/:api',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'recovery',
    loadChildren: () => import('./pages/recovery/recovery.module').then( m => m.RecoveryPageModule)
  },
  {
    path: 'new',
    loadChildren: () => import('./pages/new/new.module').then( m => m.NewPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'text',
    loadChildren: () => import('./modals/text/text.module').then( m => m.TextPageModule)
  },
  {
    path: 'fixed-text/:id',
    loadChildren: () => import('./pages/fixed-text/fixed-text.module').then( m => m.FixedTextPageModule)
  },
  {
    path: 'preview',
    loadChildren: () => import('./modals/preview/preview.module').then( m => m.PreviewPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/general/general.module').then( m => m.GeneralPageModule)
  },
  {
    path: 'profile/auth',
    loadChildren: () => import('./pages/profile/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'license',
    loadChildren: () => import('./pages/license/license.module').then( m => m.LicensePageModule)
  },
  {
    path: 'list/partner',
    loadChildren: () => import('./pages/partner/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'partner/created',
    loadChildren: () => import('./pages/partner/created/created.module').then( m => m.CreatedPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/profile/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'delete/account',
    loadChildren: () => import('./pages/profile/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
