export const environment = {
  production: true,
  url: 'https://sign-dev.procedebahia.com.br/',
  apiURL: 'https://sign-dev.procedebahia.com.br/api',
  firebaseServerKey: '591305399219', //id rementente
  keyAnalytics: 'G-7ZWQMNHG13',
  firebase:{
    apiKey: 'AIzaSyCm518sEnud3zSmnGj3fglP0ER1tMdP1jI',
    authDomain: 'sign-7b0c7.firebaseapp.com',
    projectId: 'sign-7b0c7',
    storageBucket: 'sign-7b0c7.appspot.com',
    messagingSenderId: '591305399219',
    appId: '1:591305399219:web:c00e664afa8cccbd16c9c6',
    measurementId: 'G-4PYXVEHM6V'
  }
};
