import { Injectable } from '@angular/core';
// eslint-disable-next-line no-var
declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }


  startTrackerWithId(id) {
    gtag('config', id);
  }

  trackView(pageUrl: string, screenName: string) {}

  trackEvent(category, action, label?, value?) {}
}
