import { environment } from './../environments/environment.prod';
import { ToastService } from './services/toast.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Push, PushOptions, PushObject } from '@ionic-native/push/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Nova Assinatura',  id: '', api: '', url: 'new', icon: 'add-circle', lines: 'none'},
    { title: 'Pendentes',  id: '2', api: 'file/2/get', url: '/folder/2', icon: 'warning', lines: 'none'},
    { title: 'Assinados',  id: '1', api: 'file/1/get', url: '/folder/1', icon: 'shield-checkmark', lines: 'none' },
    { title: 'Cancelados', id: '0', api: 'file/0/get', url: '/folder/0', icon: 'trash-bin', lines: 'full' },

    { title: 'Enviados',  id: '3', api: 'file', url: '/folder/3', icon: 'send', lines: 'none' },
    { title: 'Recebidos', id: '4', api: 'subscriber', url: '/folder/4', icon: 'mail', lines: 'full' },
    /*{ title: 'Favoritos', id: '5', api: 'favorite', url: '/folder/5', icon: 'heart', lines: 'full' },*/

    /**
     * 3=> Sobre; 2 Validade Juridica
     */

    { title: 'Configurações', id: '8', api: '', url: '/configuration', icon: 'settings', lines: 'none' },
    //{ title: 'Licença de uso', id: '9', api: '', url: '/license', icon: 'receipt', lines: 'none' },
    { title: 'Sobre', id: '7', api: '', url: '/fixed-text/3', icon: 'book', lines: 'none' },
    { title: 'Validade Jurídica', id: '8', api: '', url: '/fixed-text/2', icon: 'checkbox', lines: 'full' },
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(private route: Router,
    private push: Push,
    private platform: Platform,
    private toastService: ToastService,
    public alertController: AlertController,
    private analyticsService: AnalyticsService) {
    this.appPages.forEach(data => {
        localStorage.setItem(data.id, JSON.stringify({title: data.title, url: data.api}));
    });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.initializeFirebase();
      this.analyticsService.startTrackerWithId(environment.keyAnalytics);
    });
  }

  async logout(){

    const alert = await this.alertController.create({
      header: 'Deseja sair?',
      message: '',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Sair',
          handler: () => {
            localStorage.setItem('token', '');
            this.route.navigateByUrl('login');
          }
        }
      ]
    });
    await alert.present();
  }


  async presentAlertConfirm(title, body) {
    const alert = await this.alertController.create({
      header: title,
      message: body,
      buttons: [
        {
          text: 'ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }
      ]
    });

    await alert.present();
  }


  private initializeFirebase() {
    const options: PushOptions = {
      android: {
        senderID: environment.firebaseServerKey
      },
      browser: {},
      ios: {
        alert: 'true',
        badge: 'true',
        sound: 'true'
      },
      windows: {}
    };

    const pushObject: PushObject = this.push.init(options);
    pushObject.on('registration').subscribe(res => localStorage.setItem('device_token',` ${res.registrationId}`));
    pushObject.on('notification').subscribe(res => this.presentAlertConfirm(res.title, res.message));
  }



}
